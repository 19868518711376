export default {
  props: {
    // the options of BetterScroll
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
